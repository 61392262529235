import React from "react";
import Header from "../components/Header";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="page-content">
      <div className="panel shapy-background">
        <Header />
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
