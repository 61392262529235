import React from 'react'
import collinImage from '../assets/collin.png'
import sabeekImage from '../assets/sabeek.png'
import '../main.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'

const Team = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  return (
    <div className='page-content'>
      <div className='shapy-background'>
        <Header />
        <div className='team-container'>
          <div className='team-member-box'>
            <img src={collinImage} alt='Collin Barnwell' />
            <div className='team-member-content'>
              <h2>Collin Barnwell</h2>
              <h3>CEO</h3>
              <p>
                Collin is a software leader and engineer with extensive
                experience building and shipping safety-critical IoT and camera
                devices, including a device that has been implanted in humans.
                He's worked at Google and Verily Life Sciences.
              </p>
            </div>
          </div>
          <div className='team-member-box'>
            <img src={sabeekImage} alt='Sabeek Pradhan' />
            <div className='team-member-content'>
              <h2>Sabeek Pradhan</h2>
              <h3>CTO</h3>
              <p>
                Sabeek is a computer vision expert with extensive experience
                building systems for high-accuracy vehicle, pedestrian and bike
                detection and tracking at the self-driving car companies Zoox,
                Waymo and Applied Intuition, and his software has been deployed
                on public roads in fully driverless vehicles.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Team
