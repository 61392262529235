import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import demoVideo from '../assets/demo-video.mp4'
import speedDemo from '../assets/speed-demo.mp4'
import pedDemo from '../assets/ped-demo.mp4'
import waitTimeDemo from '../assets/wait-time-demo.mp4'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import ScrollArrow from '../components/ScrollArrow'

const Home = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  return (
    <div className='page'>
      <div className='panel panel1' id='panel1'>
        <div className='overlay'></div>
        <Header />
        <div className='first-panel-content'>
          <h1>Human-centric traffic lights, powered by AI</h1>
          <p className='subtitle'>
            We make AI-powered traffic signal systems that optimize signals in
            real time by tracking vehicles and pedestrians around the
            intersection for shorter wait times and safer, pedestrian-friendly
            cities
          </p>
        </div>
        <ScrollArrow targetPanel='panel2' />
      </div>

      <div className='panel panel2 shapy-background' id='panel2'>
        <div>
          <h2 className='my-2'>
            Real-time perception of all road users in 3D allows us to choose the
            best signal change at every moment
          </h2>
        </div>
        <video
          id='demo-video'
          autoPlay
          muted
          controls
          playsInline
          preload='auto'
          loop
        >
          <source
            src={demoVideo}
            type='video/mp4'
            style={{ filter: 'brightness(0.5)' }}
          />
          Unable to play demo video
        </video>
        <ScrollArrow targetPanel='panel3' />
      </div>

      <div className='panel panel3 shapy-background' id='panel3'>
        <div>
          <h2 className='my-2'>
            Optimize wait times for all road users by understanding every
            vehicle
          </h2>
        </div>
        <video
          id='wait-time-demo'
          autoPlay
          muted
          controls
          playsInline
          preload='auto'
          loop
          className='w-full h-full object-cover'
        >
          <source
            src={waitTimeDemo}
            type='video/mp4'
            style={{ filter: 'brightness(0.5)' }}
          />
          Unable to play demo video
        </video>
        <ScrollArrow targetPanel='panel4' />
      </div>

      <div className='panel panel4 shapy-background' id='panel4'>
        <div>
          <h2 className='my-2'>
            Give red lights to speeding vehicles in high-injury corridors
          </h2>
        </div>
        <video
          id='speed-demo'
          autoPlay
          muted
          controls
          playsInline
          preload='auto'
          loop
          className='w-full h-full object-cover'
        >
          <source
            src={speedDemo}
            type='video/mp4'
            style={{ filter: 'brightness(0.5)' }}
          />
          Unable to play demo video
        </video>
        <ScrollArrow targetPanel='panel5' />
      </div>

      <div className='panel panel5 shapy-background' id='panel5'>
        <div>
          <h2 className='my-2'>
            Protect pedestrians who need extra time to cross
          </h2>
        </div>
        <video
          id='ped-demo'
          autoPlay
          muted
          controls
          playsInline
          preload='auto'
          loop
          className='w-full h-full object-cover'
        >
          <source
            src={pedDemo}
            type='video/mp4'
            style={{ filter: 'brightness(0.5)' }}
          />
          Unable to play demo video
        </video>
        <ScrollArrow targetPanel='panel6' />
      </div>

      <div className='panel panel6 bullet-point-panel-background' id='panel6'>
        <div className='overlay'></div>
        <div className='flex-content'>
          <div style={{ maxWidth: '800px', marginBottom: '80px' }}>
            <h1 style={{ textAlign: 'left', marginBottom: '50px' }}>
              Roundabout isn't like other systems.
            </h1>
            <p>
              More than simply detecting the presence of vehicles in fixed boxes
              near the intersection, Roundabout integrates with existing cameras
              and hardware and anticipates the movements of every vehicle,
              cyclist, and pedestrian to optimize signal changes in real time,
              and in doing so helps your city:
            </p>
          </div>
          <div
            style={{
              maxWidth: '800px',
              paddingBottom: '200px'
            }}
          >
            <ul className='custom-list mt-12' style={{ textAlign: 'left' }}>
              <li>
                Prioritize buses & emergency vehicles with no additional
                hardware
              </li>
              <li>Reliably detect and anticipate cyclists and pedestrians</li>
              <li>Save hours of cumulative wait times for all road users</li>
              <li>
                Avoid resident complaints about legacy detection systems making
                bad decisions
              </li>
            </ul>
          </div>
        </div>
        <ScrollArrow targetPanel='panel7' />
      </div>

      <div
        className='panel shapy-background panel7 relative h-screen'
        id='panel7'
      >
        <div className='flex flex-col items-center justify-center h-[calc(100%-160px)]'>
          <h1 className='text-center mb-8'>
            Let's talk about how we can help your city!
          </h1>
          <a className='book-demo-btn mt-8' href='/contact'>
            Schedule a demo
          </a>
        </div>
        <Footer className='absolute bottom-0 w-full' />
      </div>
    </div>
  )
}

export default Home
