import React from "react";
import { useForm } from "@formspree/react";
import ReactGA from "react-ga4";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xjkblrqd");

  if (state.succeeded) {
    ReactGA.event({
      category: "Contact Form",
      action: "Submit",
      label: "Contact form successfully submitted",
    });
    return (
      <div>
        <p>Thanks for getting in touch, we'll reach out to you shortly!</p>
      </div>
    );
  } else if (state.submitting) {
    return (
      <div>
        <p>Submitting…</p>
      </div>
    );
  }

  return (
    <>
      <form id="contactForm" className="contact-form" onSubmit={handleSubmit}>
        <h2>Let's Talk!</h2>
        <p>How can we reach you to schedule a demo or answer your questions?</p>
        <hr />
        <input
          type="hidden"
          name="_url"
          value="https://roundabout.tech/contact"
        />
        <label htmlFor="name">Name:</label>
        <input
          placeholder="Your Name"
          type="text"
          id="name"
          name="name"
          required
        />
        <label htmlFor="city">City or company:</label>
        <input
          placeholder="City or Company"
          type="text"
          id="city"
          name="city"
          required
        />
        <label htmlFor="email">Email Address:</label>
        <input
          placeholder="Email Address"
          type="email"
          id="email"
          name="email"
          required
        />
        <hr />
        <button type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    </>
  );
};

export default ContactForm;
