// ScrollArrow.js
import React from 'react'

const ScrollArrow = ({ targetPanel }) => {
  const handleScroll = () => {
    console.log('ScrollArrow clicked, targeting:', targetPanel)
    const element = document.getElementById(targetPanel)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    } else {
      console.error('Target panel not found:', targetPanel)
    }
  }

  return (
    <div
      className='scroll-arrow'
      onClick={handleScroll}
      role='button'
      tabIndex={0}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        bottom: '40px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        pointerEvents: 'auto'
      }}
    >
      &#x25BC;
    </div>
  )
}

export default ScrollArrow
